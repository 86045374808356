<template>
     <div class="main ">
          <div style="text-align: center;width:100% ;" class="">
               <h1>Improving story-telling one slide at a time</h1>
               <h4 class="worksans">
                    Slyde.ai makes your content consumable, accessible, and aesthetic.
               </h4>

               <div class="controls">
                    <div v-if="state == 0">
                         <b-button style="position:relative;top:10px;" class="custombtn1" type="is-primary" @click="state = 1"
                              >Upload your Slide</b-button
                         >
                    </div>
                    <div v-else-if="state == 1">
                         <file-pond
                              :class="{ hasfile: hasfile }"
                              class="filepond"
                              name="test"
                              :files="files"
                              ref="pondsmall"
                              label-idle="Select or Drop file"
                              v-bind:allow-multiple="false"
                              accepted-file-types="application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              v-on:updatefiles="handleFileUpdate"
                              v-on:removefile="handleFileRemove"
                         />
                    </div>

                    <div v-if="this.hasfile && this.state > 0 && this.state < 4">
                         <div><b-input class="emailinput" v-if="state == 1" v-model="email" type="email" placeholder="Email"> </b-input></div>

                         <button class=" is-primary loadingbtn " :class="{ loading: isLoading }" @click="uploadBtnAction">
                              <div class="loadingContainer">
                                   <div class="loadingbg" :style="{ width: progress + '%' }">
                                        <span class="btncontent ">
                                             <span v-if="state == 1">Upload</span>

                                             <span v-if="state == 3">View Results</span>
                                        </span>
                                   </div>
                              </div>
                         </button>

                         <span v-if="this.state > 1" class="status worksans">
                              {{ statuses[statusCode] }}
                         </span>
                    </div>

                    <div v-if="state == 4">
                         <div style="font-size:18px">
                              Thanks for uploading your slides, We'll be in touch soon 🎊
                         </div>
                         <button class="button tryanother" style="margin-top:20px" @click="reset">
                              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path
                                        d="M14.2794 5.30604L13.5418 4.448C12.9164 3.79117 12.164 3.26845 11.3302 2.91165C10.4964 2.55485 9.59872 2.37143 8.69179 2.37256C4.99002 2.37256 1.98669 5.37589 1.98669 9.07766C1.98669 12.7794 4.99002 15.7828 8.69179 15.7828C10.0786 15.7827 11.4312 15.3528 12.5636 14.5523C13.696 13.7518 14.5525 12.62 15.0152 11.3127"
                                        stroke="#F2836B"
                                        stroke-width="1.11752"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                   />
                                   <path
                                        d="M16.5144 3.53965V7.40137C16.5144 7.54956 16.4555 7.69168 16.3507 7.79647C16.2459 7.90126 16.1038 7.96013 15.9556 7.96013H12.0939C11.5959 7.96013 11.3466 7.35842 11.6986 7.0064L15.5607 3.14433C15.9127 2.79161 16.5144 3.04166 16.5144 3.53965Z"
                                        fill="#F2836B"
                                   />
                              </svg>
                              <span> Try another</span>
                         </button>
                    </div>

                    <img
                         v-if="!this.$store.state.isdarkmode && !isdarkmode"
                         src="@/assets/images/Pointing.png"
                         alt=""
                         class="pointingimage illustration"
                    />
               </div>
          </div>
     </div>
</template>

<script>
     import vueFilePond from "vue-filepond";

     import axios from "axios";
     import "filepond/dist/filepond.min.css";

     export default {
          data() {
               return {
                    isdarkmode: JSON.parse(localStorage.getItem("isdarkmode")),

                    file: undefined,
                    fileName: undefined,

                    hasfile: false,
                    files: [],
                    email: "",
                    state: 0,
                    isLoading: false,
                    progress: 100,

                    fileTypes: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],

                    statuses: [
                         "Uploading your file...",
                         "Reading the slides...",
                         "Checking all alignments...",
                         "Assessing fonts & colors...",
                         "Creating DSGN score...",
                         "Almost ready, wait for it...",
                         "Polishing the report...",
                         "Your presentation is Ready!",
                    ],
                    statusCode: 0,
                    pptURL: undefined,
                    jsonURL: undefined,

                    reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
               };
          },

          components: {
               FilePond: vueFilePond(),
          },

          watch: {
               progress(newVal) {
                    // if (newVal === 0) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 50) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 60) {
                    //      this.statusCode = 1;
                    // } else if (newVal < 69) {
                    //      this.statusCode = 2;
                    // } else if (newVal < 77) {
                    //      this.statusCode = 3;
                    // } else if (newVal < 84) {
                    //      this.statusCode = 4;
                    // } else if (newVal < 90) {
                    //      this.statusCode = 5;
                    // } else if (newVal < 100) {
                    //      this.statusCode = 6;
                    // } else if (newVal == 100) {
                    //      this.statusCode = 7;
                    // }
               },
          },
          methods: {
               handleFileUpdate(x) {
                    const file = x[0].file;
                    this.file = file;
                    this.files = [file];
                    this.hasfile = true;
                    this.fileName = this.file.name.split(" ").join("_");

                    if (this.fileTypes.indexOf(file.type) == -1) {
                         this.handleFileRemove();
                         this.processError("Please select a .pptx file");
                    }
               },

               handleFileRemove() {
                    this.hasfile = false;
                    this.fileName = undefined;
                    this.file = undefined;
                    this.files = [];
               },
               uploadBtnAction() {
                    if (this.isEmailValid()) {
                         this.startUpload();
                    } else {
                         this.$buefy.toast.open({
                              message: "Please enter a vaild email",
                              position: "is-bottom",
                              type: "is-danger",
                              duration: 3000,
                         });
                    }
               },

               isEmailValid() {
                    return this.email == "" ? "" : this.reg.test(this.email) ? true : false;
               },
               startUpload() {
                    if (this.state == 1 && this.hasfile) {
                         this.state = 2;
                         this.isLoading = true;
                         this.progress = 0;
                         this.getUploadUrl();
                    } else if (this.state == 3 && !this.isLoading) {
                         this.$router.push({
                              name: "RESULT",
                              query: { jsonURL: encodeURIComponent(this.jsonURL), pptURL: encodeURIComponent(this.pptURL), encoded: true },
                         });
                    }
               },

               async getUploadUrl() {
                    try {
                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              {
                                   type: "PUT",
                                   fileName: this.fileName,
                              },
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }
                         const url = response.data.url;

                         this.progress = 0;
                         this.uploadPPT(url);
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later");
                    }
               },

               async uploadPPT(url) {
                    try {
                         const response = await axios.put(url, this.file, {
                              headers: {
                                   "Content-Type": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                              },
                              onUploadProgress: (progressEvent) => {
                                   const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 98));
                                   this.progress = uploadPercentage;
                              },
                         });

                         console.log("upload response:", response);

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }

                         this.progress = 98;

                         this.updateDB();
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later");
                    }
               },

               async updateDB() {
                    try {
                         const response = await axios.post("https://us-central1-slydeai.cloudfunctions.net/webAPI/api/v1/add", {
                              filename: this.fileName,
                              userEmail: this.email,
                         });

                         console.log(response);

                         if (!response || response.status !== 201) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }

                         this.progress = 100;
                         this.state = 4;
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later");
                    }
               },

               // async processPPT() {
               //      try {
               //           const incrementor = setInterval(() => {
               //                const range = 40;
               //                const delta = 90 - this.progress;

               //                this.progress = this.progress + (0.5 * delta) / range;

               //                if (this.progress > 89) {
               //                     clearInterval(incrementor);
               //                }
               //           }, 200);

               //           const response = await axios.get(`https://api.slyde.ai/presentation?key=${this.fileName}`);
               //           console.log("process response:", response);
               //           clearInterval(incrementor);

               //           if (!response || response.status !== 200) {
               //                this.processError("There was an error while processing file, Please try again later");
               //                return;
               //           }

               //           const pptName = response.data.ppt;
               //           const jsonName = response.data.json;

               //           this.progress = 90;
               //           this.getDownloadURL(jsonName, pptName);
               //      } catch (e) {
               //           this.processError("There was an error while processing file, Please try again later");
               //      }
               // },

               // async getDownloadURL(jsonName, pptName) {
               //      try {
               //           console.log("jsonName", jsonName);
               //           console.log("pptName", pptName);

               //           const response = await axios.post(
               //                "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
               //                {
               //                     type: "GET",
               //                     jsonName: jsonName,
               //                     pptName: pptName,
               //                },
               //                {
               //                     headers: {
               //                          Accept: "application/json",
               //                          "Content-Type": "application/json;charset=UTF-8",
               //                     },
               //                }
               //           );
               //           console.log("download url response:", response);
               //           if (!response || response.status !== 200) {
               //                this.processError("There was an error while processing file, Please try again later");
               //                return;
               //           }

               //           this.jsonURL = response.data.jsonUrl;
               //           this.pptURL = response.data.pptUrl;

               //           console.log(this.jsonURL, this.pptURL);

               //           this.progress = 90;

               //           this.fetchJSONData();
               //      } catch (e) {
               //           this.processError("There was an error while processing file, Please try again later");
               //      }
               // },

               // async fetchJSONData() {
               //      try {
               //           const response = await axios.get(this.jsonURL, {
               //                onDownloadProgress: (progressEvent) => {
               //                     const currentProgress = Math.round((progressEvent.loaded / progressEvent.total) * 10);

               //                     this.progress = 90 + currentProgress;
               //                },
               //           });

               //           if (!response || response.status !== 200) {
               //                this.processError("Couldn't fetch Data");
               //                return;
               //           }

               //           this.$store.state.dataJSON = response.data;

               //           this.progress = 100;

               //           this.isLoading = false;
               //           this.state = 3;
               //      } catch (e) {
               //           this.processError("Couldn't fetch Data");
               //      }
               // },

               processError(errorMessage) {
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: "is-bottom",
                         type: "is-danger",
                         duration: 6000,
                    });
                    this.reset();
               },

               reset() {
                    this.state = 1;
                    this.isLoading = false;
                    this.progress = 100;

                    this.pptURL = undefined;
                    this.jsonURL = undefined;
               },
          },
     };
</script>

<style lang="scss" scoped>
     .main {
          padding-top: 20px;
     }

     .controls {
          margin-top: 80px;
          min-height: 160px;
          position: relative;
          padding-bottom: 280px;
     }

     .pointingimage {
          width: 250px;
          height: auto;
          position: absolute;
          bottom: 0;
          right: calc(50% - 270px);
     }

     .emailinput {
          margin-bottom: 15px;
     }

     @media only screen and (max-width: 600px) {
          .controls {
               margin-top: 50px;
               padding-bottom: 220px;
          }
          .main {
               padding-bottom: 150px;
          }
          .pointingimage {
               width: 190px;
               height: auto;
               position: absolute;
               bottom: 30px;
               right: calc(50% - 200px);
          }
     }
</style>
